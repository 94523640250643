import AuthCall from "./auth";
import { ApiResponse, EndpointRequest, FilterPaginationDateTime } from "../types/base";
import { ImageMetadataAPI, ImageStatus } from "../../types/image";
import { removeUndefined } from "../../utils/global";

const ImageAPI = {
  getImageByUUID: async ({ uuid, signal }: { uuid: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secured/image-metadata/instances/${uuid}`,
    signal,
  }) as ApiResponse<ImageMetadataAPI>,

  getAllImages: async ({ startTime, endTime, offset, limit, signal }: FilterPaginationDateTime & EndpointRequest) => await AuthCall.get({
    endpoint: '/secured/image-metadata/instances',
    params: { startTime, endTime, offset, limit },
    signal,
  }) as ApiResponse<ImageMetadataAPI[]>,

  getImagesByPatient: async ({ id, startTime, endTime, offset, limit, signal }: { id: string } & FilterPaginationDateTime & EndpointRequest) => await AuthCall.get({
    endpoint: `/secured/image-metadata/byPatient/${id}`,
    params: { startTime, endTime, offset, limit },
    signal,
  }) as ApiResponse<ImageMetadataAPI[]>,

  getImagesByStatus: async ({ status, startTime, endTime, offset, limit, signal }: { status: ImageStatus } & FilterPaginationDateTime & EndpointRequest) => await AuthCall.get({
    endpoint: `/secured/image-metadata/byStatus/${status}`,
    params: { startTime, endTime, offset, limit },
    signal,
  }) as ApiResponse<ImageMetadataAPI[]>,

  reprocessImage: async ({ imageId, overrideKitId, overridePatientId, signal }: { imageId: string, overrideKitId?: string, overridePatientId?: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secured/image-metadata/reprocess/${imageId}`,
    data: removeUndefined({
      testkit: overrideKitId     ?? undefined,
      patient: overridePatientId ?? undefined,
    }),
    signal,
  }) as ApiResponse<ImageMetadataAPI>,

  clearImageDetection: async ({ imageId, signal }: { imageId: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secured/image-metadata/blank/${imageId}`,
    signal,
  }) as ApiResponse<ImageMetadataAPI>,
};

export default ImageAPI;
