import React from "react";
import { FormikValues } from "formik";
import { Autocomplete, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Filter } from "./types";

export const getOverrides = <T extends FormikValues>(values: T, filters: Filter<T>[]) => {
  const finalValues: any = {};

  filters.forEach(filter => {
    if (filter.type === 'boolean') {
      const override = typeof(filter.override) === 'function' ? filter.override(values) : filter.override;

      if (override !== undefined) {
        finalValues[filter.id] = override;
      }
    }
  });

  return finalValues as T;
}

export const getDefaultValue = (filter: Filter) => {
  switch (filter.type) {
    case 'text':
      return '';
    case 'boolean':
      return false;
    default:
      return null;
  }
}

export const getFilterComponent = <T extends FormikValues>(
  values: T,
  filter: Filter<T>,
  onChange: (id: string, value: any, triggerSearch?: boolean) => void,
  onSearch?: (values: T) => void,
) => {
  if (filter.visible !== undefined && !filter.visible) {
    return <React.Fragment key={filter.id}/>
  }

  const disabled = !!filter.disabled && (typeof(filter.disabled) === 'function' ? filter.disabled(values) : filter.disabled)

  switch (filter.type) {
    case 'text':
      return (
        <TextField
          key={filter.id}
          disabled={disabled}
          label={filter.label}
          value={values[filter.id] || ''}
          onChange={e => onChange(filter.id, e.target.value, filter.searchOnChange)}
          onKeyDown={e => { if (!filter.searchOnChange && e.key === 'Enter') { onSearch?.(values) }}}
          sx={{ width: `${filter.width || 160}px` }}
        />
      );
    case 'boolean':
      const override = typeof(filter.override) === 'function' ? filter.override(values) : filter.override;
      return (
        <FormControlLabel
          key={filter.id}
          disabled={disabled || override !== undefined}
          label={filter.label}
          control={<Checkbox
            checked={override ?? values[filter.id] ?? false}
            onChange={(_, val) => onChange(filter.id, val, true)}
          />}
          sx={{ mx: 0 }}
        />
      );
    case 'date':
      return (
        <DatePicker
          key={filter.id}
          disabled={disabled}
          label={filter.label}
          value={values[filter.id] || null}
          onChange={val => onChange(filter.id, val, true)}
          sx={{ width: 170 }}
        />
      );
    case 'options':
      return (
        <Autocomplete
          disablePortal
          key={filter.id}
          disabled={disabled}
          options={filter.options}
          value={values[filter.id] || null}
          onChange={(_, val) => onChange(filter.id, val, true)}
          onInputChange={filter.onInputChange}
          getOptionKey={filter.getKey}
          getOptionLabel={filter.getLabel}
          isOptionEqualToValue={filter.isEqual}
          sx={{ width: `${filter.width || 200}px` }}
          renderInput={params => <TextField {...params} label={filter.label}/>}
          renderOption={filter.renderOption}
        />
      );
  }
}
