import { BLANK, removeUndefined } from "../utils/global";
import { AccountRole } from "./account";

export enum TestkitStatusAPI {
  NEW           = 'NEW',
  STOCK         = 'STOCK',
  ALLOCATED     = 'ALLOCATED',
  ISSUED        = 'ISSUED',
  DONE          = 'DONE',
}

export enum TestkitStatusUI {
  NEW           = 'NEW',
  STOCK         = 'STOCK',
  ALLOCATED     = 'ALLOCATED',
  DISPATCHED    = 'DISPATCHED',
  ISSUED        = 'ISSUED',
  DONE          = 'DONE',
}

export const ADMIN_STATUSES = [
  TestkitStatusUI.NEW,
  TestkitStatusUI.STOCK,
  TestkitStatusUI.ALLOCATED,
  TestkitStatusUI.DISPATCHED,
  TestkitStatusUI.ISSUED,
  TestkitStatusUI.DONE,
]

export const MIDWIFE_STATUSES = [
  TestkitStatusUI.ALLOCATED,
  TestkitStatusUI.DISPATCHED,
  TestkitStatusUI.ISSUED,
  TestkitStatusUI.DONE,
]

export const getTestkitStatusLabel = (kit?: Testkit) => {
  switch (kit?.status) {
    case TestkitStatusAPI.NEW:
      return 'New';
    case TestkitStatusAPI.STOCK:
      return 'Stock';
    case TestkitStatusAPI.ALLOCATED:
      return !!kit.dispatched ? 'Dispatched' : 'Allocated';
    case TestkitStatusAPI.ISSUED:
      return 'Issued';
    case TestkitStatusAPI.DONE:
      return 'Finished';
    default:
      return BLANK;
  }
}

export const getKitStatusUILabel = (status?: TestkitStatusUI) => {
  switch (status) {
    case TestkitStatusUI.NEW:
      return 'New';
    case TestkitStatusUI.STOCK:
      return 'Stock';
    case TestkitStatusUI.ALLOCATED:
      return 'Allocated';
    case TestkitStatusUI.DISPATCHED:
      return 'Dispatched';
    case TestkitStatusUI.ISSUED:
      return 'Issued';
    case TestkitStatusUI.DONE:
      return 'Finished';
    default:
      return BLANK;
  }
}

export const TestkitStatusPriority = {
  [TestkitStatusAPI.ALLOCATED]: 4,
  [TestkitStatusAPI.STOCK]: 3,
  [TestkitStatusAPI.NEW]: 2,
  [TestkitStatusAPI.ISSUED]: 1,
  [TestkitStatusAPI.DONE]: 0,
}

export type StatusTransition = {
  status: TestkitStatusAPI,
  timestamp: string,      // ISO DateTime
}

export type Testkit = {
  objectType: 'testkit',
  dummyFlag: boolean,
  id: string,             // 123-456-789
  serial: number,
  patient?: string,       // P-123-456-7889
  midwife?: string,
  site?: string,

  status: TestkitStatusAPI,
  transitions: StatusTransition[],
  dispatched?: string,
  
  manufacturer?: string,
  paperLot?: string,
  paperExpiry?: string,   // Date
  swabLot?: string,
  swabExpiry?: string,    // Date
}

export const getDispatchedFilterOverride = (filter: TestkitFilterUI) => {
  const status = filter.status;

  // Show finished -> show dispatched
  if (filter.incFinished) {
    return true;
  }

  if (!!status) {
    switch (status) {
      case TestkitStatusUI.DISPATCHED:
      case TestkitStatusUI.ISSUED:
      case TestkitStatusUI.DONE:
        // Only show dispatched kits
        return true;
      default:
        // Do not show dispatched kits
        return false;
    }
  }
  else {
    // No status selected -> use user-defined filter
    return undefined;
  }
}

export const getCompletedFilterOverride = (filter: TestkitFilterUI) => {
  const status = filter.status;

  if (!status) {
    return undefined;
  }
  else {
    return status === TestkitStatusUI.DONE;
  }
}

export type TestkitFilterAPI = {
  idPattern?: string,
  siteId?: string,
  status?: TestkitStatusAPI,
  midwife?: string,
  manufacturer?: string,
  dispatched?: boolean,
  includeCompleted?: boolean,
}

export type TestkitFilterUI = {
  idPattern?: string,
  status?: TestkitStatusUI | null,
  siteId?: string,
  midwife?: string,
  manufacturer?: string,
  incDispatched?: boolean,
  incFinished?: boolean,
}

export const convertFilterToAPI = (filter: TestkitFilterUI, role?: AccountRole) => {
  // Dispatched: Undefined -> all kits, True -> only dispatched, False -> only not dispatched
  let dispatched: boolean | undefined;
  
  if (role === AccountRole.ADMIN) {
    // If filtering by status, override the 'include dispatched' filter
    if (!!filter.status) {
      switch (filter.status) {
        case TestkitStatusUI.DISPATCHED:
        case TestkitStatusUI.ISSUED:
        case TestkitStatusUI.DONE:
          dispatched = true;
          break;
        default:
          dispatched = false;
          break;
      }
    }
    // Otherwise, UI true/false -> API undefined/false
    else {
      dispatched = filter.incDispatched ? undefined : false;
    }
  }
  else {
    // MEDIC/POWER_MEDIC -> all kits
    dispatched = undefined;
  }

  // dispatched = true;
  
  return removeUndefined({
    idPattern: filter.idPattern || undefined,
    status: filter.status === TestkitStatusUI.DISPATCHED ? TestkitStatusAPI.ALLOCATED : (filter.status || undefined),
    siteId: filter.siteId || undefined,
    midwife: filter.midwife || undefined,
    manufacturer: filter.manufacturer || undefined,
    dispatched,
    includeCompleted: filter.status === TestkitStatusUI.DONE || filter.incFinished || undefined,
  } as TestkitFilterAPI) as TestkitFilterAPI;
}

export type TestkitSorterAPI = {
  statusSort?: boolean,
  descending?: boolean,
}

export type TestkitSorterUI = {
  column: 'id' | 'status',
  desc: boolean,
}

export const convertSorterToAPI = (sorter: TestkitSorterUI) => {
  return removeUndefined({
    statusSort: sorter.column === 'status' ? true : undefined,
    descending: sorter.desc ? true : undefined,
  } as TestkitSorterAPI) as TestkitSorterAPI;
}