import { Box, ButtonBase, Tooltip, Typography } from "@mui/material";
import { Error as InvalidIcon } from '@mui/icons-material';
import { getImageStatusUILabel, ImageMetadataAPI, ImageType, isImageValid } from "../../types/image";
import Image from "../../components/Image";

const ImagePreview = ({
  image,
  imageType,
  onClick,
}: {
  image: ImageMetadataAPI,
  imageType: ImageType,
  onClick?: (image: ImageMetadataAPI) => void,
}) => {
  return (
    <ButtonBase
      sx={theme => ({ borderRadius: 3, overflow: 'hidden', backgroundColor: theme.palette.background.paper, boxShadow: theme.shadows[1] })}
      onClick={onClick && (() => onClick(image))}
    >
      <Image
        // src={getImageType(image, imageType)}
        image={image}
        imageType={imageType}
        alt={`${image.patient} | ${image.imageTimestamp}`}
        maxWidth={300}
        maxHeight={300}
        forceOrientation='vertical'
      />

      <Box sx={{
        position: 'absolute', top: 0,
        width: '100%',
        backgroundColor: '#fffd',
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 24, m: 0.5, ml: 1 }}>
          <Typography variant='body2' color={image.dummyFlag ? (theme => theme.palette.info.main) : undefined}>{image.patient}</Typography>

          {!isImageValid(image) && (
            <Tooltip disableInteractive title={getImageStatusUILabel(image.status)} sx={{ ml: 'auto' }}>
              <InvalidIcon color='error'/>
            </Tooltip>
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
}

export default ImagePreview;