import React from "react";
import { GenericAbortSignal } from "axios";
import { Autocomplete, Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { searchPatients, selectPatientSearchResults } from "../../../redux/slices/patient";
import { selectCrf, selectIsUploadingCrf, uploadCrfData } from "../../../redux/slices/crf";
import { convertCrfToApi, convertCrfToUi } from "../../../utils/crf";
import { ChangeEventHandlerDate, ChangeEventHandlerRadio, ChangeEventHandlerText, CrfSection } from "../types";
import { CrfData } from "../../../types/crf";
import { Patient } from "../../../types/patient";
import { renderPatientOption } from "../../../components/autocompleteOptions";
import Section from "../../../components/Section";
import DemographicsSection from "./DemographicsSection";
import MedicalSection from "./MedicalSection";
import ASPRESection from "./ASPRESection";
import EndpointsSection from "./EndpointsSection";
import MaternalSection from "./MaternalSection";
import UltrasoundSection from "./UltrasoundSection";
import BirthDataSection from "./BirthDataSection";
import NeonatalSection from "./NeonatalSection";

const DataSection = ({
  openSection,
  patient,
  onClickSection,
  onChangeSelectedPatient: onChangeSelectedId,
}: {
  openSection?: CrfSection,
  patient?: Patient,
  onClickSection: (id: CrfSection) => void,
  onChangeSelectedPatient: (patient: Patient | null) => void,
}) => {
  const dispatch = useDispatch();
  const isUploading = useSelector(selectIsUploadingCrf);
  const patientSearchResults = useSelector(selectPatientSearchResults);
  const crfRedux = useSelector(selectCrf);

  const [crfLocal, setCrfLocal] = React.useState<CrfData>({ id: ''});
  const [isEditing, setEditing] = React.useState(false);

  const headerRefs = React.useRef<any>(null)

  React.useEffect(() => {
    setCrfLocal(crfRedux ? convertCrfToUi(crfRedux) : { id: ''})
  }, [crfRedux]);
  
  const getRefMap = React.useCallback(() => {
    if (!headerRefs?.current) {
      headerRefs.current = new Map();
    }
    return headerRefs.current;
  }, [headerRefs]);

  const setRef = React.useCallback((ref: unknown, id: CrfSection) => {
    const map = getRefMap();
    if (ref) map.set(id, ref);
    else map.delete(id);
  }, [getRefMap]);

  const scrollToHeader = React.useCallback((id: CrfSection) => {
    const map = getRefMap();
    const ref = map.get(id);
    ref?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [getRefMap]);

  React.useEffect(() => {
    if (!!openSection) {
      scrollToHeader(openSection);
    }
  }, [openSection, scrollToHeader]);

  const findPatients = ({ idPattern, signal }: { idPattern?: string, signal?: GenericAbortSignal }) => {
    dispatch(searchPatients({
      idPattern,
      includeCompleted: true,
      signal,
    }));
  }

  const handleInputChangeID = (e: React.SyntheticEvent<Element, Event> | null, value: string) => {
    // 'change' event -> user is typing a partial ID -> search using input as filter
    // 'click' or 'keydown' event -> user has selected/cleared ID -> clear filter
    findPatients({ idPattern: e?.type === 'change' ? value : undefined });
  }

  const handleChangePatient = (e: React.SyntheticEvent<Element, Event>, value: Patient | null) => {
    onChangeSelectedId(value);
  }

  const onClickEdit = () => {
    setEditing(true)
  }

  const onClickSave = () => {
    dispatch(uploadCrfData({ data: convertCrfToApi({ patient, crf: crfLocal }) }))
    .then((result) => {
      if (result.meta.requestStatus !== 'rejected') {
        setEditing(false)
      }
    })
  }

  const onClickCancel = () => {
    setEditing(false)
    setCrfLocal(crfRedux ? convertCrfToUi(crfRedux) : { id: ''})
  }

  const onChangeText: ChangeEventHandlerText = (e) => {
    setCrfLocal({ ...crfLocal, [e.target.name]: e.target.value })
  }
  
  const onChangeBoolean = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCrfLocal({ ...crfLocal, [e.target.name]: checked })
  }

  const onChangeBooleanInverted = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCrfLocal({ ...crfLocal, [e.target.name]: !checked })
  }

  const onChangeDate: ChangeEventHandlerDate = (name, value) => {
    setCrfLocal({ ...crfLocal, [name]: value })
  }

  const onChangeRadio: ChangeEventHandlerRadio = (e) => {
    setCrfLocal({ ...crfLocal, [e.target.name]: e.target.value })
  }

  const sectionProps = {
    crf: crfLocal,
    editing: isEditing,
    onChangeBoolean,
    onChangeBooleanInverted,
    onChangeText,
    onChangeRadio,
    onChangeDate,
    onClick: onClickSection,
  }

  return (
    <Section sx={{ alignSelf: 'stretch', width: '840px', overflow: 'hidden', p: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', px: 3, py: 2, alignItems: 'center' }}>
        <Autocomplete
          disabled={isEditing}
          disablePortal
          options={patientSearchResults}
          getOptionLabel={patient => patient.id}
          isOptionEqualToValue={(option, value) => value.id === option.id}
          value={patient || null}
          sx={{ width: 240, mr: 'auto' }}
          onChange={handleChangePatient}
          onInputChange={handleInputChangeID}
          renderInput={params => <TextField {...params} label='Study ID'/>}
          renderOption={renderPatientOption}
        />

        {isEditing ? (
          <>
            <Button variant='text' sx={theme => ({ mr: 1, color: theme.palette.text.secondary })} onClick={onClickCancel}>Cancel</Button>
            <Button variant='text' disabled={isUploading} onClick={onClickSave}>Save</Button>
          </>
        ) : (
          <Button variant='text' disabled={!patient?.id} onClick={onClickEdit}>Edit</Button>
        )}
      </Box>

      <Divider/>

      {!patient ? (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', pt: 8 }}>
          <Typography variant='h5'>Please select a Study ID</Typography>
        </Box>
      ) : (
        <Box sx={{ px: 3, pb: 2, overflow: 'auto' }}>
          <DemographicsSection
            ref={ref => setRef(ref, CrfSection.Demographics)}
            open={openSection === CrfSection.Demographics}
            patient={patient}
            {...sectionProps}
          />
          <MedicalSection
            ref={ref => setRef(ref, CrfSection.Medical)}
            open={openSection === CrfSection.Medical}
            {...sectionProps}
          />
          <ASPRESection
            ref={ref => setRef(ref, CrfSection.ASPRE)}
            open={openSection === CrfSection.ASPRE}
            {...sectionProps}
          />
          <EndpointsSection
            ref={ref => setRef(ref, CrfSection.Endpoints)}
            open={openSection === CrfSection.Endpoints}
            {...sectionProps}
          />
          <MaternalSection
            ref={ref => setRef(ref, CrfSection.Maternal)}
            open={openSection === CrfSection.Maternal}
            {...sectionProps}
          />
          <UltrasoundSection
            ref={ref => setRef(ref, CrfSection.Ultrasound)}
            open={openSection === CrfSection.Ultrasound}
            {...sectionProps}
          />
          <BirthDataSection
            ref={ref => setRef(ref, CrfSection.BirthData)}
            open={openSection === CrfSection.BirthData}
            {...sectionProps}
          />
          <NeonatalSection
            ref={ref => setRef(ref, CrfSection.Neonatal)}
            open={openSection === CrfSection.Neonatal}
            {...sectionProps}
          />
        </Box>
      )}
    </Section>
  );
}

export default DataSection;