import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { REGEX_FLOAT_1_2, REGEX_FLOAT_1_3, REGEX_FLOAT_3_1, REGEX_INT_3 } from "../../../utils/crf";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const ASPRESection = React.forwardRef(({
  crf,
  editing,
  onChangeBoolean,
  onChangeText,
  onChangeDate,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  const theme = useTheme();

  return (
    <Expander
      ref={ref}
      id={CrfSection.ASPRE}
      open={open}
      onClick={onClick}
    >
      <Typography variant='h6' color={theme.palette.text.secondary}>ASPRE Risk of PE {'<'} 37 weeks:</Typography>
      {getInput({ type: 'number', name: 'clin_asprePreeclampsiaRisk', label: 'Risk Ratio', prefix: '1 :', format: /^\d{0,5}$/, value: crf.clin_asprePreeclampsiaRisk, disabled: !editing, onChange: onChangeText, sx: { mt: 1, mb: 3 }})}

      <Typography variant='h6' color={theme.palette.text.secondary}>Booking Blood Pressure:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'clin_sbp', label: 'SBP', format: REGEX_INT_3, value: crf.clin_sbp, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'number', width: 150, name: 'clin_dbp', label: 'DBP', format: REGEX_INT_3, value: crf.clin_dbp, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'date', name: 'clin_bloodPressureTestDate', label: 'Date of Testing', value: crf.clin_bloodPressureTestDate, disabled: !editing, onChange: onChangeDate })}
      </Box>

      <Typography variant='h6' color={theme.palette.text.secondary}>PAPP-A:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'clin_pappAMom', label: 'MoM Value', format: REGEX_FLOAT_1_3, value: crf.clin_pappAMom, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'date', name: 'clin_pappATestDate', label: 'Date of Testing', value: crf.clin_pappATestDate, disabled: !editing, onChange: onChangeDate})}
      </Box>

      <Typography variant='h6' color={theme.palette.text.secondary}>PLGF:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'clin_pigfValue', label: 'PLGF Value', format: REGEX_FLOAT_1_3, value: crf.clin_pigfValue, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'date', name: 'clin_pigfValueDate', label: 'Date of Testing', value: crf.clin_pigfValueDate, disabled: !editing, onChange: onChangeDate})}
      </Box>

      <Typography variant='h6' color={theme.palette.text.secondary}>CRL:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'clin_crl', label: 'mm Value', format: REGEX_FLOAT_3_1, value: crf.clin_crl, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'date', name: 'clin_crlTestDate', label: 'Date of Testing', value: crf.clin_crlTestDate, disabled: !editing, onChange: onChangeDate})}
      </Box>

      <Typography variant='h6' color={theme.palette.text.secondary}>Uterine Artery PI:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'clin_uterineArteryPiLeft', label: 'Left', format: REGEX_FLOAT_1_2, value: crf.clin_uterineArteryPiLeft, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'number', width: 150, name: 'clin_uterineArteryPiRight', label: 'Right', format: REGEX_FLOAT_1_2, value: crf.clin_uterineArteryPiRight, disabled: !editing, onChange: onChangeText })}
      </Box>

      <Typography variant='h6' color={theme.palette.text.secondary}>Aspirin:</Typography>
      {getInput({ type: 'checkbox', name: 'clin_prescribedAspirin', label: 'Prescribed Aspirin', value: crf.clin_prescribedAspirin, disabled: !editing, onChange: onChangeBoolean })}
    </Expander>
  );
})

export default ASPRESection;