import { Checkbox, FormControlLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { CrfInputs } from "./types"
import RadioGroup from "../../components/RadioGroup";
import RadioButton from "../../components/RadioButton";
import TextInput from "../../components/TextInput";
import NumberInput from "../../components/NumberInput";

export const getInput = (input: CrfInputs) => {
  switch (input.type) {
    case 'number':
      return (
        <NumberInput
          disabled={input.disabled}
          decimal={input.decimal}
          format={input.format}
          name={input.name}
          label={input.label}
          textAdornmentPosition={!!input.suffix ? 'end' : 'start'}
          textAdornment={input.suffix || input.prefix}
          value={input.value || ''}
          onChange={input.onChange}
          sx={{ width: input.width || 240, minWidth: input.width || 240, ...input.sx }}
        />
      );
      
    case 'text':
      return (
        <TextInput
          multiline
          disabled={input.disabled}
          name={input.name}
          label={input.label}
          value={input.value || ''}
          onChange={input.onChange}
          sx={{ width: input.width, minWidth: input.width, ...input.sx }}
        />
      );
      
    case 'date':
      return (
        <DatePicker
          disabled={input.disabled}
          name={input.name}
          label={input.label}
          value={input.value || null}
          // onChange={input.onChange}
          onChange={(value) => { input.onChange?.(input.name, value) }}
          sx={{ width: 240, minWidth: 240, ...input.sx }}
        />
      );
      
    case 'checkbox':
      return (
        <FormControlLabel
          disabled={input.disabled}
          name={input.name}
          label={input.label}
          control={<Checkbox checked={input.value || false} onChange={input.onChange}/>}
          sx={{ width: 'fit-content', minHeight: input.height || 40, ...input.sx }}
        />
      );

    case 'radio':
      return (
        <RadioGroup
          disabled={input.disabled}
          name={input.name}
          label={input.label}
          value={input.value || null}
          row={input.row}
          onChange={input.onChange}
          sx={input.sx}
        >
          {input.options.map((option) => (
            <RadioButton
              key={option.id}
              // disabled={input.disabled}
              value={option.id}
              label={option.label}
              labelHint={option.hint}
            />
          ))}
        </RadioGroup>
      );
  }
}