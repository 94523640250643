import React from "react";
import { Box, Typography } from "@mui/material";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const EndpointsSection = React.forwardRef(({
  crf,
  editing,
  onChangeBoolean,
  onChangeBooleanInverted,
  onChangeText,
  onChangeDate,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  const severePE = editing && crf.clin_severePreeclampsia;

  return (
    <Expander
      ref={ref}
      id={CrfSection.Endpoints}
      open={open}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
        {getInput({ type: 'checkbox', name: 'clin_preeclampsiaWithProteinuria', label: 'Pre-eclampsia (with significant proteinuria)', value: crf.clin_preeclampsiaWithProteinuria, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_preeclampsiaWithProteinuriaDate', label: 'Date of test/diagnosis', value: crf.clin_preeclampsiaWithProteinuriaDate, disabled: !editing || !crf.clin_preeclampsiaWithProteinuria, onChange: onChangeDate })}
      </Box>

      {getInput({ type: 'checkbox', name: 'clin_severePreeclampsia', label: 'Severe Pre-eclampsia', value: crf.clin_severePreeclampsia, disabled: !editing, onChange: onChangeBoolean })}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 4 }}>
        {getInput({ type: 'checkbox', name: 'clin_severeHypertension', label: 'Severe hypertension >110 / >160 mmHg', value: crf.clin_severeHypertension, disabled: !severePE, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_severeHypertensionDate', label: 'Date of test/diagnosis', value: crf.clin_severeHypertensionDate, disabled: !severePE || !crf.clin_severeHypertension, onChange: onChangeDate })}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 4, mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_severePreeclampsiaWithSymptoms', label: 'With symptoms (headache, blurred or flashed vision, epigastic pain, vomiting, oedema)', value: crf.clin_severePreeclampsiaWithSymptoms, disabled: !severePE, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_severePreeclampsiaWithSymptomsDate', label: 'Date of appearance', value: crf.clin_severePreeclampsiaWithSymptomsDate, disabled: !severePE || !crf.clin_severePreeclampsiaWithSymptoms, onChange: onChangeDate })}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 4, mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_severePreeclampsiaWithBiochemical', label: 'Biochemical evidence of hepatic/renal impairment', value: crf.clin_severePreeclampsiaWithBiochemical, disabled: !severePE, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_severePreeclampsiaWithBiochemicalDate', label: 'Date of test/diagnosis', value: crf.clin_severePreeclampsiaWithBiochemicalDate, disabled: !severePE || !crf.clin_severePreeclampsiaWithBiochemical, onChange: onChangeDate })}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 4, mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_severePreeclampsiaWithHaematological', label: 'Haematological evidence of organ impairment', value: crf.clin_severePreeclampsiaWithHaematological, disabled: !severePE, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_severePreeclampsiaWithHaematologicalDate', label: 'Date of test/diagnosis', value: crf.clin_severePreeclampsiaWithHaematologicalDate, disabled: !severePE || !crf.clin_severePreeclampsiaWithHaematological, onChange: onChangeDate })}
      </Box>
      
      {getInput({ type: 'text', name: 'clin_severePreeclampsiaComments', label: 'Comments', value: crf.clin_severePreeclampsiaComments, disabled: !editing, onChange: onChangeText, sx: { mt: 2, mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Eclampsia:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, mb: 3 }}>
        {getInput({ type: 'checkbox', name: 'clin_preeclampsia', label: 'Eclampsia (pre-eclampsia and convulsions)', value: crf.clin_preeclampsia, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_eclampsiaDate', label: 'Date of appearance', value: crf.clin_eclampsiaDate, disabled: !editing || !crf.clin_preeclampsia, onChange: onChangeDate })}
      </Box>
      
      {/* Should these be radio buttons? If so can we simplify to one 'Date of appearance' box? */}
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Pregnancy-Induced Hypertension / PIH (without significant proteinuria):</Typography>
      {getInput({ type: 'checkbox', name: 'clin_pih', label: 'No PIH', value: (crf.clin_pih !== undefined ? !crf.clin_pih : false), disabled: !editing, onChange: onChangeBooleanInverted})}
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_mildPregnancyHypertension', label: 'Mild: 90-99 / 140-149 mmHg', value: crf.clin_mildPregnancyHypertension, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_firstMildPregnancyHypertensionDate', label: 'Date of appearance', value: crf.clin_firstMildPregnancyHypertensionDate, disabled: !editing || !crf.clin_mildPregnancyHypertension, onChange: onChangeDate })}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_moderatePregnancyHypertension', label: 'Moderate: 100-109 / 150-159 mmHg', value: crf.clin_moderatePregnancyHypertension, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_firstModeratePregnancyHypertensionDate', label: 'Date of appearance', value: crf.clin_firstModeratePregnancyHypertensionDate, disabled: !editing || !crf.clin_moderatePregnancyHypertension, onChange: onChangeDate })}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, mb: 3 }}>
        {getInput({ type: 'checkbox', name: 'clin_severePregnancyHypertension', label: 'Severe: >110 / >160 mmHg', value: crf.clin_severePregnancyHypertension, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_firstSeverePregnancyHypertensionDate', label: 'Date of appearance', value: crf.clin_firstSeverePregnancyHypertensionDate, disabled: !editing || !crf.clin_severePregnancyHypertension, onChange: onChangeDate })}
      </Box>
      
      {/* Should these be radio buttons? If so can we simplify to one 'Date of appearance' box? */}
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Urinary Protein:</Typography>
      {getInput({ type: 'checkbox', name: 'clin_noUrinary', label: 'No proteinuria', value: (crf.clin_proteinuria !== undefined ? !crf.clin_proteinuria : false), disabled: !editing, onChange: onChangeBooleanInverted })}
      
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_anyUrinary', label: 'Any detection of \'+\' in a urine sample', value: crf.clin_anyUrinary, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_anyUrinaryDate', label: 'Date of Test/Diagnosis', value: crf.clin_anyUrinaryDate, disabled: !editing || !crf.clin_anyUrinary, onChange: onChangeDate })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, mb: 3 }}>
        {getInput({ type: 'checkbox', name: 'clin_significantUrinaryProtein', label: 'Significant: >30 mg/mmol in a spot urinary protein:creatinine sample', value: crf.clin_significantUrinaryProtein, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_significantUrinaryProteinDate', label: 'Date of Test/Diagnosis', value: crf.clin_significantUrinaryProteinDate, disabled: !editing || !crf.clin_significantUrinaryProtein, onChange: onChangeDate })}
      </Box>

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Symptoms of Pre-eclampsia:</Typography>
      {getInput({ type: 'checkbox', name: 'pe_none', label: 'None', value: crf.pe_none, disabled: !editing, onChange: onChangeBoolean })}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'pe_headache', label: 'Headache', value: crf.pe_headache, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'pe_headacheDate', label: 'Date of appearance', value: crf.pe_headacheDate, disabled: !editing || !crf.pe_headache, onChange: onChangeDate })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'pe_blurredVision', label: 'Blurred or flashing vision', value: crf.pe_blurredVision, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'pe_blurredVisionDate', label: 'Date of appearance', value: crf.pe_blurredVisionDate, disabled: !editing || !crf.pe_blurredVision, onChange: onChangeDate })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'pe_epigastricPain', label: 'Epigastric pain', value: crf.pe_epigastricPain, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'pe_epigastricPainDate', label: 'Date of appearance', value: crf.pe_epigastricPainDate, disabled: !editing || !crf.pe_epigastricPain, onChange: onChangeDate })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'pe_vomitting', label: 'Vomiting', value: crf.pe_vomitting, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'pe_vomittingDate', label: 'Date of appearance', value: crf.pe_vomittingDate, disabled: !editing || !crf.pe_vomitting, onChange: onChangeDate })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
        {getInput({ type: 'checkbox', name: 'pe_oedema', label: 'Oedema: sudden swelling of face, hands, and/or feet (att. rapid weight gain)', value: crf.pe_oedema, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'pe_oedemaDate', label: 'Date of appearance', value: crf.pe_oedemaDate, disabled: !editing || !crf.pe_oedema, onChange: onChangeDate })}
      </Box>

      {getInput({ type: 'text', name: 'pe_comments', label: 'Comments', value: crf.pe_comments, disabled: !editing, onChange: onChangeText, sx: { mt: 2, mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>HELLP Syndrome (Haemolysis, Elevated Liver Enzymes, and Low Platelet Count):</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, mb: 1 }}>
        {getInput({ type: 'checkbox', name: 'clin_hellp', label: 'HELLP Syndrome', value: crf.clin_hellp, disabled: !editing, onChange: onChangeBoolean, sx: { flexGrow: 1 }})}
        {getInput({ type: 'date', name: 'clin_hellpDate', label: 'Date of test/diagnosis', value: crf.clin_hellpDate, disabled: !editing || !crf.clin_hellp, onChange: onChangeDate })}
      </Box>

      {getInput({ type: 'checkbox', name: 'clin_asatAlat', label: 'ASAT / ALAT >70 U/l', value: crf.clin_asatAlat, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'clin_lowPlatelets', label: 'Low Platelets <100x10 /l', value: crf.clin_lowPlatelets, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'clin_hemolysis', label: 'Hemolysis, haptoglobin <0.3 g/l, LDH >600 U/l, bilirubin >24 umol/l', value: crf.clin_hemolysis, disabled: !editing, onChange: onChangeBoolean })}
    </Expander>
  );
})

export default EndpointsSection;