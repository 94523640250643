import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useSelector } from "../../redux/hooks";
import { selectSelectedTestkit } from '../../redux/slices/testkit';
import { selectMidwifeNames, selectRole, selectSiteNames } from '../../redux/slices/account';
import { TestkitStatusAPI, getTestkitStatusLabel } from '../../types/testkit';
import { isoDateTimeToDate } from '../../utils/dateTime';
import { getStatusTimestampDateTime, getTestkitLocation } from '../../utils/testkit';
import Section from '../../components/Section';
import Parameter from '../../components/Parameter';
import { AccountRole } from '../../types/account';
import TestkitAPI from '../../api/endpoints/testkit';

const TITLE_WIDTH_1 = 100;
const VALUE_WIDTH_1 = 220;
const VALUE_WIDTH_2 = 120;

const DetailsSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const selectedTestkit = useSelector(selectSelectedTestkit);
  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);
  const midwives = useSelector(selectMidwifeNames);

  // const handleDebug = () => {
  //   if (selectedTestkit) {
  //     TestkitAPI.updateKitData({
  //       testkit: selectedTestkit,
  //       swabLot: '54321',
  //       paperLot: '12345',
  //     })
  //     .catch(e => {console.warn(e)});
  //   }
  // }

  return (
    <Section sx={{ alignSelf: 'stretch', overflowY: 'auto', ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, alignItems: 'start' }}>
        <Typography variant='h5'>Kit Details</Typography>

        {selectedTestkit?.dummyFlag && (
          <Typography variant='h5' sx={theme => ({ color: theme.palette.info.main, ml: 2, alignSelf: 'center' })}>DUMMY KIT</Typography>
        )}

        {/* {role === AccountRole.ADMIN && (
          <Button disabled={!selectedTestkit} onClick={handleDebug} sx={{ ml: 'auto' }}>Debug</Button>
        )} */}
      </Box>

      <Parameter title='Study ID' primaryValue={selectedTestkit?.id} titleWidth={TITLE_WIDTH_1}/>
      <Parameter title='Primary ID' primaryValue={selectedTestkit?.id !== selectedTestkit?.patient ? selectedTestkit?.patient : undefined} titleWidth={TITLE_WIDTH_1}/>
      {role !== AccountRole.MEDIC && (
        <Parameter title='Site' primaryValue={selectedTestkit?.site && sites[selectedTestkit.site]} titleWidth={TITLE_WIDTH_1}/>
      )}
      <Parameter title='Midwife' primaryValue={selectedTestkit?.midwife && midwives[selectedTestkit.midwife]} titleWidth={TITLE_WIDTH_1}/>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h6'>Status</Typography>
          <Parameter title='Status' primaryValue={getTestkitStatusLabel(selectedTestkit)} titleWidth={TITLE_WIDTH_1} valueWidth={VALUE_WIDTH_1}/>
          {/* <Parameter title='Manufacturer' primaryValue={selectedTestkit?.manufacturer} titleWidth={TITLE_WIDTH_1} valueWidth={VALUE_WIDTH_1}/> */}
          <Parameter title='Location' primaryValue={selectedTestkit && getTestkitLocation({ kit: selectedTestkit, role, sites })} titleWidth={TITLE_WIDTH_1} valueWidth={VALUE_WIDTH_1}/>
          <Parameter title='Dispatched' primaryValue={selectedTestkit?.dispatched && isoDateTimeToDate(selectedTestkit.dispatched)} titleWidth={TITLE_WIDTH_1} valueWidth={VALUE_WIDTH_1}/>
          <Parameter title='Issued' primaryValue={getStatusTimestampDateTime({
            testkit: selectedTestkit,
            status: TestkitStatusAPI.ISSUED,
          })} titleWidth={TITLE_WIDTH_1} valueWidth={VALUE_WIDTH_1}/>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h6'>Batch Info</Typography>
          <Parameter title='Test Paper Lot' primaryValue={selectedTestkit?.paperLot} valueWidth={VALUE_WIDTH_2}/>
          <Parameter title='Test Paper Expiry' primaryValue={selectedTestkit?.paperExpiry && isoDateTimeToDate(selectedTestkit.paperExpiry)} valueWidth={VALUE_WIDTH_2}/>
          <Parameter title='Swab Lot' primaryValue={selectedTestkit?.swabLot} valueWidth={VALUE_WIDTH_2}/>
          <Parameter title='Swab Expiry' primaryValue={selectedTestkit?.swabExpiry && isoDateTimeToDate(selectedTestkit.swabExpiry)} valueWidth={VALUE_WIDTH_2}/>
        </Box>
      </Box>
    </Section>
  );
}

export default DetailsSection;