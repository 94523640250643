import React from "react";
import { FormikValues } from "formik";
import { Box, SxProps } from "@mui/material";
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { Filter } from "./types";
import { getDefaultValue, getFilterComponent, getOverrides } from "./utils";
import IconButton from "../IconButton";

const SearchFilter = <T extends FormikValues> ({
  initialValues,
  filters,
  showClear = true,
  onSearch,
  sx,
}: {
  initialValues: T,
  filters: Filter<T>[],
  showClear?: boolean,
  onSearch?: (values: T) => void,
  sx?: SxProps,
}) => {
  const [rawValues, setRawValues] = React.useState<T>(initialValues);
  const [defaultValues, setDefaultValues] = React.useState<T | undefined>(undefined);
  const [filterElements, setFilterElements] = React.useState<React.ReactNode[]>([]);
  const [canClear, setCanClear] = React.useState(false);

  React.useEffect(() => {
    const newDefaults: any = {};
    const newFilters: React.ReactNode[] = [];
    const overrides = getOverrides(rawValues, filters);

    filters.forEach(filter => {
      newDefaults[filter.id] = getDefaultValue(filter);
      newFilters.push(getFilterComponent({ ...rawValues, ...overrides }, filter, handleChangeValue, onSearch));
    });

    setDefaultValues(newDefaults);
    setFilterElements(newFilters);
  }, [filters, rawValues]);

  // Check if filter is different to default state
  React.useEffect(() => {
    let anyDiff = false;

    filters.some((filter) => {
      // If not both empty (empty string/false/null/etc.) AND not equal -> value is not equivalent to default
      if (!(rawValues[filter.id] === undefined || rawValues[filter.id] === null || rawValues[filter.id] === false || rawValues[filter.id] === '')) {
        anyDiff = true;
        return true;
      }
    });
    
    setCanClear(anyDiff);
  }, [rawValues, setCanClear]);

  const handleChangeValue = (id: string, value: any, triggerSearch: boolean = false) => {
    const newValues = { ...rawValues, [id]: value };
    setRawValues(newValues);

    if (triggerSearch) {
      onSearch?.({ ...newValues, ...getOverrides(newValues, filters) });
    }
  }

  const handleClearValues = (triggerSearch: boolean = true) => {
    if (!defaultValues) {
      return;
    }

    const newValues = { ...defaultValues };
    setRawValues(newValues);

    if (triggerSearch) {
      onSearch?.({ ...newValues, ...getOverrides(newValues, filters) });
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, ...sx }}>
      <IconButton
        variant='contained'
        title='Filter results'
        onClick={() => onSearch?.({ ...rawValues, ...getOverrides(rawValues, filters) })}
        sx={{ mr: 1 }}
      ><SearchIcon/></IconButton>
      
      {filterElements}

      {showClear && canClear && (
        <IconButton variant='icon' title='Clear Filter' onClick={() => handleClearValues()}><ClearIcon/></IconButton>
      )}
    </Box>
  );
}

export default SearchFilter;