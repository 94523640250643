import React from "react";
import { Box, SxProps } from "@mui/material";

const ScreenBox = ({
  sx,
  children,
}: {
  sx?: SxProps,
  children?: React.ReactNode,
}) => {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', gap: 1,
      flexGrow: 1, p: 1,
      boxSizing: 'border-box',
      overflow: 'auto',
      justifyContent: 'center', alignItems: 'stretch',
      ...sx,
    }}>
      {children}
    </Box>
  );
}

export default ScreenBox;