import React from "react";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const MaternalSection = React.forwardRef(({
  crf,
  editing,
  onChangeBoolean,
  onChangeText,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  return (
    <Expander
      ref={ref}
      id={CrfSection.Maternal}
      open={open}
      onClick={onClick}
    >
      {getInput({ type: 'checkbox', name: 'maternal_cns', label: 'CNS', value: crf.maternal_cns, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'maternal_gdm', label: 'GDM', value: crf.maternal_gdm, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'maternal_cardiorespiratory', label: 'Cardiorespiratory', value: crf.maternal_cardiorespiratory, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'maternal_haematological', label: 'Haematological/hepatic/renal complications', value: crf.maternal_haematological, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'maternal_hdu', label: 'HDU-admission', value: crf.maternal_hdu, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'maternal_placentalAbruption', label: 'Placental abruption', value: crf.maternal_placentalAbruption, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'maternal_iv', label: 'Requirements for i.v. antihypertensive treatment (magnesium sulphate, hydralazine, or labetalol', value: crf.maternal_iv, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'text', name: 'maternal_comments', label: 'Comments', value: crf.maternal_comments, disabled: !editing, onChange: onChangeText, sx: { mt: 2 }})}
    </Expander>
  );
})

export default MaternalSection;