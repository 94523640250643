import React from "react";
import { Box, Typography } from "@mui/material";
import { Patient } from "../../../types/patient";
import { CrfEthnicityData } from "../../../types/crf";
import { convertDateToUi, REGEX_FLOAT_3_1, REGEX_INT_2, REGEX_INT_3 } from "../../../utils/crf";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const DemographicsSection = React.forwardRef(({
  crf,
  editing,
  patient,
  onChangeText,
  onChangeRadio,
  onChangeDate,
  open,
  onClick,
}: CrfSectionProps & {
  patient?: Patient,
}, ref) => {
  return (
    <Expander
      ref={ref}
      id={CrfSection.Demographics}
      open={open}
      onClick={onClick}
    >
      {/* {getInput({ type: 'date', name: 'base_estimatedDueDate', label: 'Due Date (By Scan)', value: crf.base_estimatedDueDate, disabled: !editing, onChange: onChangeDate, sx: { mb: 2 }})} */}
      {getInput({ type: 'date', name: 'base_estimatedDueDate', label: 'Due Date (By Scan)', value: convertDateToUi(patient?.dueDate), disabled: true, onChange: onChangeDate, sx: { mb: 2 }})}
      {getInput({ type: 'number', name: 'base_maternalAge', label: 'Maternal Age', format: REGEX_INT_2, value: crf.base_maternalAge, disabled: !editing, onChange: onChangeText, sx: { mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Height & Weight:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'base_height', label: 'Height', suffix: 'cm', format: REGEX_INT_3, value: crf.base_height, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'number', width: 150, name: 'base_weight', label: 'Weight', suffix: 'kg', format: REGEX_FLOAT_3_1, value: crf.base_weight, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'date', name: 'base_heightWeightTakenDate', label: 'Date of Testing', value: crf.base_heightWeightTakenDate, disabled: !editing, onChange: onChangeDate})}
      </Box>

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Ethnicity:</Typography>
      {getInput({ type: 'radio', name: 'base_ethnicity', options: CrfEthnicityData, value: crf.base_ethnicity, disabled: !editing, onChange: onChangeRadio })}
    </Expander>
  );
})

export default DemographicsSection;