import { ImageMetadataAPI, ImageMetadataUI, ImageType } from "../types/image";

export const getImageSrc = ({ image, type }: { image?: ImageMetadataAPI | ImageMetadataUI, type: ImageType }) => {
  if (!image) {
    return undefined;
  }
  else if (type === 'mask' && image.maskFileUrl) {
    return image.maskFileUrl;
  }
  else if (type === 'whiteBalance' && image.whitebalanceFileUrl) {
    return image.whitebalanceFileUrl;
  }
  else {
    return image.rawFileUrl;
  }
}
