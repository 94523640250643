import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import TestkitAPI from "../../api/endpoints/testkit";
import { ApiErrorResponse, EndpointRequest, FilterPagination } from "../../api/types/base";
import { Testkit, TestkitSorterUI, TestkitFilterUI, convertFilterToAPI, convertSorterToAPI } from "../../types/testkit";
import { showErrorMessage } from "../utils";
import { mergeArrays } from "../../utils/global";
import { removePatientIdPrefixTestkits } from "../../utils/testkit";
import { selectRole } from "./account";
import { Toast } from "../../components/Toast";

interface TestkitState {
  testkitFilter: TestkitFilterUI,
  testkitSorter: TestkitSorterUI,
  isLoadingTestkits: boolean,
  totalTestkits: number,
  testkitsInf: Testkit[],
  selectedTestkit?: Testkit,

  isLoadingAvailableTestkits: boolean,
  availableTestkits: Testkit[],

  isLoadingTestkitSearchResults: boolean,
  testkitSearchResults: Testkit[],
}

const initialState: TestkitState = {
  // testkitFilter: { idPattern: '', status: null, incDispatched: false, incFinished: false },
  testkitFilter: {},
  testkitSorter: { column: 'status', desc: false },
  isLoadingTestkits: false,
  totalTestkits: -1,
  testkitsInf: [],
  selectedTestkit: undefined,

  isLoadingAvailableTestkits: false,
  availableTestkits: [],

  isLoadingTestkitSearchResults: false,
  testkitSearchResults: [],
}

// NOTE: 'state' is only mutable inside 'createSlice'
export const testkitSlice = createSlice({
  name: 'testkits',
  initialState,
  reducers: {
    clearCachedTestkits: (state) => {
      state.totalTestkits = -1;
      state.testkitsInf = [];
      state.selectedTestkit = undefined;
    },
    clearCachedTestkitSearchResults: (state) => {
      state.testkitSearchResults = [];
    },
    setSelectedStockTestkit: (state, action: PayloadAction<Testkit | undefined>) => {
      state.selectedTestkit = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Thunk: getTestkitsInf
    builder.addCase(getTestkitsInf.pending, (state, action) => {
      state.isLoadingTestkits = true;

      // If specified, update stored filter
      if (action.meta.arg.filter) {
        state.testkitFilter = action.meta.arg.filter;
      }
      
      // If specified, update stored sorter
      if (action.meta.arg.sorter) {
        state.testkitSorter = action.meta.arg.sorter;
      }
    });
    builder.addCase(getTestkitsInf.fulfilled, (state, action) => {
      const initTotal = state.totalTestkits;
      const testkits: Testkit[] = action.payload?.[0].data || [];

      if (action.payload?.length > 1) {
        state.totalTestkits = action.payload[1].data?.count;
      }

      removePatientIdPrefixTestkits(testkits);
      
      // If refreshing or if filter/sorter changed -> reset list of kits
      if (initTotal < 0 || action.meta.arg.refresh || action.meta.arg.filter || action.meta.arg.sorter) {
        state.selectedTestkit = undefined;
        state.testkitsInf     = testkits;
      }
      // Otherwise -> merge new kits into existing list
      else {
        state.testkitsInf = mergeArrays(state.testkitsInf, testkits, (a, b) => a.id === b.id)
      }

      state.isLoadingTestkits = false;
    });
    builder.addCase(getTestkitsInf.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve testkit stock data' });
      state.isLoadingTestkits = false;
    });

    // Thunk: searchTestkits
    builder.addCase(searchTestkits.pending, (state, action) => { state.isLoadingTestkitSearchResults = true; });
    builder.addCase(searchTestkits.fulfilled, (state, action) => {
      state.isLoadingTestkitSearchResults = false;

      const testkits = action.payload?.data || [];
      removePatientIdPrefixTestkits(testkits);
      state.testkitSearchResults = testkits;
    });
    builder.addCase(searchTestkits.rejected, (state, action) => {
      state.isLoadingTestkitSearchResults = false;
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve testkit search results' });
    });

    // Thunk: generateNewIDs
    builder.addCase(generateNewIDs.fulfilled, (state, action) => {
      Toast.success({ title: `Generated ${action.meta.arg.count} new Study IDs` });
    });
    builder.addCase(generateNewIDs.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to generate ${action.meta.arg.count} new Study IDs` });
    });

    // Thunk: requestStock
    builder.addCase(requestStock.fulfilled, (state, action) => {
      Toast.success({ title: `Allocated ${action.meta.arg.count} testkits` });
    });
    builder.addCase(requestStock.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Unable to allocate ${action.meta.arg.count} testkits` });
    });
  },
});

export default testkitSlice.reducer;
export const {
  clearCachedTestkits,
  clearCachedTestkitSearchResults,
  setSelectedStockTestkit,
} = testkitSlice.actions;

export const getTestkitsInf = createAsyncThunk('testkit/getTestkitsInf',
  async ({ filter, sorter, limit, offset, refresh, signal }: {
    filter?: TestkitFilterUI,
    sorter?: TestkitSorterUI,
    refresh?: boolean,
  } & FilterPagination & EndpointRequest, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const totalItems = selectTotalTestkits(state);

    const uiFilter = filter || selectTestkitFilter(state);
    const uiSorter = sorter || selectTestkitSorter(state);

    const params = {
      ...convertFilterToAPI(uiFilter, selectRole(state)),
      ...convertSorterToAPI(uiSorter),
      signal,
    };

    const pagination = { limit, offset };

    const apiCalls: any[] = [
      TestkitAPI.getAllKits({ ...params, ...pagination }).catch(error => rejectWithValue(error)),
    ]

    // If refreshing or if filter/sorter changed -> fetch total stock count
    if ((totalItems < 0) || refresh || filter || sorter) {
      // Call API to fetch total count
      apiCalls.push(TestkitAPI.getKitCount(params));
    }

    return Promise.all(apiCalls).catch(error => rejectWithValue(error));
  },
);

export const searchTestkits = createAsyncThunk('testkit/searchTestkits',
  async ({ signal, ...filter }: TestkitFilterUI & EndpointRequest, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    return TestkitAPI.getAllKits({
      ...(filter && convertFilterToAPI(filter, selectRole(state))),
      limit: 50,
      signal,
    })
    .catch(error => rejectWithValue(error))
  },
);

export const generateNewIDs = createAsyncThunk('testkit/generateNewIDs',
  async ({ count, signal }: { count: number } & EndpointRequest, { rejectWithValue }) => (
    TestkitAPI.generateNewKits({ count, signal })
    .catch(error => rejectWithValue(error))
));

export const requestStock = createAsyncThunk('testkit/requestStock',
  async ({ count, siteId, signal }: { count: number, siteId?: string } & EndpointRequest, { rejectWithValue }) => (
    TestkitAPI.allocateKitsToMidwife({ count, siteId, signal})
    .catch(error => rejectWithValue(error))
));

export const selectTestkitFilter = (state: RootState) => state.testkit.testkitFilter;
export const selectTestkitSorter = (state: RootState) => state.testkit.testkitSorter;
export const selectIsLoadingTestkits = (state: RootState) => state.testkit.isLoadingTestkits;
export const selectTotalTestkits = (state: RootState) => state.testkit.totalTestkits;
export const selectTestkitsInf = (state: RootState) => state.testkit.testkitsInf;
export const selectSelectedTestkit = (state: RootState) => state.testkit.selectedTestkit;

export const selectIsLoadingAvailableTestkits = (state: RootState) => state.testkit.isLoadingAvailableTestkits;
export const selectAvailableTestkits = (state: RootState) => state.testkit.availableTestkits;

export const selectIsLoadingTestkitSearchResults = (state: RootState) => state.testkit.isLoadingTestkitSearchResults;
export const selectTestkitSearchResults = (state: RootState) => state.testkit.testkitSearchResults;
