import React from "react";
import { Screen, setScreenTitle } from "../../routes/screens";
import ScreenBox from "../../components/ScreenBox";
import DetailsSection from "./DetailsSection";
import TableSection from "./TableSection";

const MidwivesScreen = () => {
  React.useEffect(() => {
    setScreenTitle(Screen.MIDWIVES);
  }, []);
  
  return (
    <ScreenBox>
      <TableSection/>
      <DetailsSection/>
    </ScreenBox>
  );
}

export default MidwivesScreen;