import React from "react";
import { Box, Typography } from "@mui/material";
import { CrfBirthData, CrfDeliveryModeData, CrfLabourData, CrfSexData, CrfSteroids, CrfSteroidsData } from "../../../types/crf";
import { REGEX_INT_3, REGEX_INT_4 } from "../../../utils/crf";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const BirthDataSection = React.forwardRef(({
  crf,
  editing,
  onChangeText,
  onChangeRadio,
  onChangeDate,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  const disableSteroids = !editing || !crf.birth_steroidDoses || (crf.birth_steroidDoses === CrfSteroids.None);

  return (
    <Expander
      ref={ref}
      id={CrfSection.BirthData}
      open={open}
      onClick={onClick}
    >
      {getInput({ type: 'date', name: 'birth_dateOfBirth', label: 'Date of Birth', value: crf.birth_dateOfBirth, disabled: !editing, onChange: onChangeDate})}
      <Box sx={{ display: 'flex', flexDirection: 'row', my: 1, gap: 2 }}>
        {getInput({ type: 'number', name: 'birth_birthOffset', label: 'Gestational Week', format: REGEX_INT_3, suffix: 'weeks', value: crf.birth_birthOffset, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'number', width: 180, name: 'birth_weight', label: 'Weight', format: REGEX_INT_4, suffix: 'grams', value: crf.birth_weight, disabled: !editing, onChange: onChangeText })}
      </Box>
      {getInput({ type: 'radio', name: 'birth_birth', options: CrfBirthData, row: true, value: crf.birth_birth, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Sex:</Typography>
      {getInput({ type: 'radio', name: 'birth_sex', options: CrfSexData, row: true, value: crf.birth_sex, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Labour:</Typography>
      {getInput({ type: 'radio', name: 'birth_labour', options: CrfLabourData, row: true, value: crf.birth_labour, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Mode of Delivery:</Typography>
      {getInput({ type: 'radio', name: 'birth_deliveryMode', options: CrfDeliveryModeData, value: crf.birth_deliveryMode, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}
      
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Preterm Birth:</Typography>
      <Typography variant='subtitle1' color={theme => theme.palette.text.secondary}>If delivered {'<'}35 weeks, did the mother receive steroids?</Typography>
      {getInput({ type: 'radio', name: 'birth_steroidDoses', options: CrfSteroidsData, row: true, value: crf.birth_steroidDoses, disabled: !editing, onChange: onChangeRadio, sx: { mb: 1 }})}
      <Typography variant='subtitle1' color={theme => !disableSteroids ? theme.palette.text.secondary : theme.palette.text.disabled}>How many days before birth were the steroids administrated?</Typography>
      {getInput({ type: 'number', width: 150, name: 'birth_steroidDays', label: '', format: REGEX_INT_3, suffix: 'days', value: crf.birth_steroidDays, disabled: disableSteroids, onChange: onChangeText, sx: { mt: 1 }})}
    </Expander>
  );
})

export default BirthDataSection;