import React from "react";
import { Typography } from "@mui/material";
import { CrfParityData, CrfSmokingData } from "../../../types/crf";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const MedicalSection = React.forwardRef(({
  crf,
  editing,
  onChangeBoolean,
  onChangeText,
  onChangeRadio,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  const disableElaboration = !editing || !crf.obs_previousRelatedOutcomes;

  return (
    <Expander
      ref={ref}
      id={CrfSection.Medical}
      open={open}
      onClick={onClick}
    >
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Obstetric History:</Typography>
      <Typography variant='subtitle1' color={theme => theme.palette.text.secondary}>Parity:</Typography>
      {getInput({ type: 'radio', name: 'obs_obstetricParity', options: CrfParityData, row: true, value: crf.obs_obstetricParity, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}
      
      {getInput({ type: 'checkbox', name: 'obs_previousPreeclampsia', label: 'Previous pre-eclampsia', value: crf.obs_previousPreeclampsia, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'obs_previousChronicPreeclampsia', label: 'Previous chronic hypertension with superimposed pre-eclampsia', disabled: !editing, value: crf.obs_previousChronicPreeclampsia, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'obs_previousHypertension', label: 'Previous gestational hypertension', value: crf.obs_previousHypertension, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'obs_previousRelatedOutcomes', label: 'Previous related outcomes', value: crf.obs_previousRelatedOutcomes, disabled: !editing, onChange: onChangeBoolean })}

      <Typography variant='subtitle1' color={theme => disableElaboration ? theme.palette.text.disabled : theme.palette.text.secondary} sx={{ mt: 1, ml: 4 }}>Elaborate:</Typography>
      {getInput({ type: 'checkbox', name: 'obs_prematureBirth', label: 'Premature birth (before 37 weeks)', value: crf.obs_prematureBirth, disabled: disableElaboration, onChange: onChangeBoolean, sx: { ml: '21px' }})}
      {getInput({ type: 'checkbox', name: 'obs_stillBirth', label: 'Stillbirth', value: crf.obs_stillBirth, disabled: disableElaboration, onChange: onChangeBoolean, sx: { ml: '21px' }})}
      {getInput({ type: 'checkbox', name: 'obs_placentalAbruption', label: 'Placetal abruption', value: crf.obs_placentalAbruption, disabled: disableElaboration, onChange: onChangeBoolean, sx: { ml: '21px' }})}
      {getInput({ type: 'checkbox', name: 'obs_fgr', label: 'Fetal growth restriction (FGR)', value: crf.obs_fgr, disabled: disableElaboration, onChange: onChangeBoolean, sx: { ml: '21px' }})}
      {getInput({ type: 'checkbox', name: 'obs_gdm', label: 'Gestational diabetes (GDM)', value: crf.obs_gdm, disabled: disableElaboration, onChange: onChangeBoolean, sx: { ml: '21px' }})}
      {getInput({ type: 'checkbox', name: 'obs_previousOther', label: 'Other perinatal or maternal complications', value: crf.obs_previousOther, disabled: disableElaboration, onChange: onChangeBoolean, sx: { ml: '21px' }})}
      {getInput({ type: 'text', name: 'obs_comments', label: 'Comments', value: crf.obs_comments, disabled: !editing, onChange: onChangeText, sx: { mt: 2, mb: 3 }})}
      
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Medical History:</Typography>
      {getInput({ type: 'checkbox', name: 'med_cardiacDisease', label: 'Cardiac disease', value: crf.med_cardiacDisease, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'med_autoimmuneDisease', label: 'Autoimmune disease', value: crf.med_autoimmuneDisease, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'med_renalDisease', label: 'Renal disease', value: crf.med_renalDisease, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'med_chronicHypertension', label: 'Chronic hypertension', value: crf.med_chronicHypertension, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'med_diabetesType1', label: 'Type 1 diabetes', value: crf.med_diabetesType1, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'checkbox', name: 'med_diabetesType2', label: 'Type 2 diabetes mellitus', value: crf.med_diabetesType2, disabled: !editing, onChange: onChangeBoolean })}
      {getInput({ type: 'text', name: 'med_comments', label: 'Comments', value: crf.med_comments, disabled: !editing, onChange: onChangeText, sx: { mt: 2, mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Smoking History:</Typography>
      {getInput({ type: 'radio', name: 'smoke_smokingType', options: CrfSmokingData, row: true, value: crf.smoke_smokingType, disabled: !editing, onChange: onChangeRadio})}
      {getInput({ type: 'text', name: 'smoke_comments', label: 'Comments (cigarettes per day)', value: crf.smoke_comments, disabled: !editing, onChange: onChangeText, sx: { mt: 2 }})}
    </Expander>
  );
})

export default MedicalSection;