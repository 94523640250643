import React from "react";
import { Box, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { CRF_SECTIONS, CrfSection } from "./types";

const Expander = React.forwardRef(({
  id,
  children,
  open,
  onClick,
}: {
  id: CrfSection,
  children: React.ReactNode,
  open: boolean,
  onClick: (id: CrfSection) => void,
}, ref) => {
  return (
    <Box ref={ref} sx={theme => ({ borderBottomColor: theme.palette.divider, borderBottomStyle: 'solid', borderBottomWidth: 1 })}>
      <Box onClick={() => onClick(id)} sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', py: 2.5 }}>
        <Typography variant='h4' sx={theme => ({ mr: 'auto', color: open ? theme.palette.text.primary : theme.palette.text.secondary })}>{CRF_SECTIONS[id]}</Typography>
        {open ? <KeyboardArrowUp fontSize='large'/> : <KeyboardArrowDown fontSize='large'/>}
      </Box>

      {open && (
        <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2 }}>
          {children}
        </Box>
      )}
    </Box>
  );
})

export default Expander;