import React from "react";
import { Box, Button, Divider, Dialog as MuiDialog, SxProps, Typography } from "@mui/material";
import { DialogCloseHandler } from "../../types/dialog";

const BaseDialog = ({
  open,
  showClose = true,
  title,
  confirmText = 'Confirm',
  confirmIcon,
  confirmDisabled = false,
  closeText = 'Cancel',
  width,
  sx,
  containerSx,
  children,
  onClose,
  onConfirm,
}: {
  open: boolean,
  showClose?: boolean,
  title?: string,
  confirmText?: string,
  confirmIcon?: React.ReactNode,
  confirmDisabled?: boolean,
  closeText?: string,
  width?: number,
  sx?: SxProps,
  containerSx?: SxProps,
  children?: React.ReactNode,
  onConfirm?: () => void,
  onClose?: DialogCloseHandler,
}) => {

  return (
    <MuiDialog open={open} onClose={onClose} PaperProps={{ sx: { width: width, maxWidth: width ?? undefined, overflow: 'hidden', overflowY: 'visible', ...sx }}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 3, py: 2, ...containerSx }}>
        {title && <Typography sx={{ mb: children ? 2 : undefined }}>{title}</Typography>}

        {children}
      </Box>

      <Divider/>

      {showClose && (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', px: 3, py: 1 }}>
          <Button variant='text' onClick={onClose ? (e => onClose(e, 'closeClick')) : undefined} sx={theme => ({ color: theme.palette.text.secondary })}>{closeText}</Button>
          {onConfirm && (
            <Button variant='text' disabled={confirmDisabled} endIcon={confirmIcon} onClick={onConfirm} sx={{ ml: 1 }}>{confirmText}</Button>
          )}
        </Box>
      )}
    </MuiDialog>
  );
}

export default BaseDialog;