import React from "react";
import { DateTime } from "luxon";
import { SxProps } from "@mui/material";
import { AgCharts } from "ag-charts-react";
import { time } from "ag-charts-community";
import { AgCartesianAxisOptions, AgCartesianChartOptions, AgLineSeriesMarkerItemStylerParams, AgLineSeriesTooltipRendererParams, AgNodeClickEvent, AgSeriesMarkerStyle, AgTooltipRendererResult, DatumCallbackParams } from "ag-charts-types";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectImages, selectSelectedPatient, selectUricAcidResults, setDisplayedImage } from "../../redux/slices/patient";
import { PatientUricAcidResults, UricAcidStatus } from "../../types/patient";
import { isoDateTimeToLuxon } from "../../utils/dateTime";
import { COLOUR_PRIMARY, COLOUR_SECONDARY } from "../../theme";
import Section from "../../components/Section";
import { ImageMetadataAPI } from "../../types/image";

export const MIN_HEIGHT = 270;
export const MAX_HEIGHT = 400;

type Datum = {
  id?: string,
  date: Date,
  uaLevel: number,
  risk: number,
  status?: UricAcidStatus,
}

const markerStyler = (
  params: DatumCallbackParams<Datum> & AgSeriesMarkerStyle & AgLineSeriesMarkerItemStylerParams,
  colour: string
) => params.datum.status === 'PARTIAL' ? {
  size: params.highlighted ? 14 : 12,
  fill: '#fff',
  stroke: colour,
  strokeWidth: 2,
} : {
  size: params.highlighted ? 14 : 12,
  fill: colour,
  stroke: '#fff',
  strokeWidth: 2,
};

const tooltipRenderer = ({ datum, xKey, yKey }: AgLineSeriesTooltipRendererParams<Datum>) => ({
  heading: DateTime.fromJSDate(datum.date).toLocaleString(DateTime.DATETIME_SHORT),
  title: datum.status,
  // data: [
  //   { label: 'Uric Acid', value: datum.uaLevel.toString() },
  //   { label: 'Risk',      value: datum.risk.toString() },
  // ],
} as AgTooltipRendererResult);

const DEFAULT_CHART_OPTIONS: AgCartesianChartOptions = {
  minHeight: MIN_HEIGHT,
  background: { fill: '#fff0' },
  padding: { bottom: 12 },
  data: [],
  series: [
    {
      type: 'line',
      xKey: 'date',
      yKey: 'uaLevel',
      yName: 'Uric Acid',
      interpolation: { type: 'smooth' },
      tooltip: { renderer: tooltipRenderer },
      stroke: COLOUR_SECONDARY,
      marker: {
        fill: COLOUR_SECONDARY,
        itemStyler: (params) => markerStyler(params, COLOUR_SECONDARY),
      },
    },
    {
      type: 'line',
      xKey: 'date',
      yKey: 'risk',
      yName: 'Risk',
      interpolation: { type: 'smooth' },
      tooltip: { renderer: tooltipRenderer },
      lineDash: [ 6, 3 ],
      stroke: COLOUR_PRIMARY,
      marker: {
        fill: COLOUR_PRIMARY,
        itemStyler: (params) => markerStyler(params, COLOUR_PRIMARY),
      },
    },
  ],
  legend: {
    position: 'right',
    listeners: {
      legendItemDoubleClick: (e) => { e.preventDefault(); },
    },
  },
  listeners: {
    seriesNodeClick: (e) => {
      console.warn('Image not found');
    },
  },
};

const DEFAULT_AXES: AgCartesianAxisOptions[] = [
  {
    type: 'number', position: 'left',  keys: ['uaLevel'], nice: false, min: 0, max: 750,
    title: { text: 'Uric Acid Level (micromoles)' },
    interval: { step: 150 },
  },
  {
    type: 'number', position: 'right', keys: ['risk'], nice: false, min: 0, max: 100,
    title: { text: 'Risk (%)' },
    interval: { step: 20 },
    label: { formatter: ({ value }) => value + '%' },
  },
];

const AXIS_TIME: AgCartesianAxisOptions = {
  type: 'time',
  position: 'bottom',
  interval: { step: time.month },
  label: { format: '%b %Y', spacing: 20 },
}

const GraphSection = ({
  height,
  sx,
}: {
  height: number,
  sx?: SxProps,
}) => {
  const dispatch = useDispatch();
  const patient = useSelector(selectSelectedPatient);
  const images = useSelector(selectImages);
  const uricAcidResults = useSelector(selectUricAcidResults);

  const [chartOptions, setChartOptions] = React.useState<AgCartesianChartOptions>({
    ...DEFAULT_CHART_OPTIONS,
    axes: [
      ...DEFAULT_AXES,
      {
        ...AXIS_TIME,
        min: Date.now(),
        max: DateTime.now().plus({ months: 6 }).toJSDate(),
      },
    ],
  });

  React.useEffect(() => {
    if (height > 0) {
      setChartOptions(value => ({
        ...value,
        height,
      }));
    }
  }, [height]);

  React.useEffect(() => {
    // No patient selected or patient has no submissions AND graph is not already empty -> Clear graph
    if ((!patient || !uricAcidResults || uricAcidResults.results.length === 0) && chartOptions.data?.length !== 0) {
      setChartOptions(value => ({
        ...value,
        data: [],
      }));

      return;
    }
    else if (!patient || !uricAcidResults || !images || patient?.uricAcidId !== uricAcidResults?.id) {
      return;
    }
    
    const startDate = isoDateTimeToLuxon(patient.startTimestamp).toJSDate();
    const dueDate   = isoDateTimeToLuxon(patient.dueDate).toJSDate();
    const data      = getGraphData(uricAcidResults, startDate, dueDate);

    setChartOptions({
      ...DEFAULT_CHART_OPTIONS,
      // height,
      axes: [
        ...DEFAULT_AXES,
        {
          ...AXIS_TIME,
          min: startDate,
          max: dueDate,
        }
      ],
      data,
      listeners: { seriesNodeClick: (e) => handleClickNode(e, images) },
    });
  }, [patient, uricAcidResults, images]);

  const getGraphData = (uaResults: PatientUricAcidResults, startDate: Date, dueDate: Date) => {
    const data = uaResults.results.map(result => (result.status !== 'ERROR' ? {
      id: result.imageId,
      date: isoDateTimeToLuxon(result.imageTimestamp).toJSDate(),
      risk: result.risk,
      uaLevel: result.uricAcidLevel,
      status: result.status,
    } as Datum : undefined))
    // Remove undefined data points or any before the start date or after the due date
    .filter(result => (result !== undefined && result.date >= startDate && result.date <= dueDate)) as Datum[];
    
    // Ensure data is sorted by date
    data.sort((a, b) => a.date.getTime() - b.date.getTime());

    let millis1: number = 0;
    let millis2: number = 0;

    // Add 'NaN' data to show gaps in submissions
    for (let i = data.length - 2; i >= 0; i--) {
      millis1 = data[i].date.getTime();
      millis2 = data[i + 1].date.getTime();

      const daysDiff = Math.abs(millis1 - millis2) / (1000 * 60 * 60 * 24);

      if (daysDiff > 10) {
        data.splice(i + 1, 0, { date: new Date((millis1 + millis2) / 2), risk: Number.NaN, uaLevel: Number.NaN });
      }
    }

    return data;
  }

  const handleClickNode = (e: AgNodeClickEvent<"seriesNodeClick", Datum>, images?: ImageMetadataAPI[]) => {
    const image = images?.find(image => image.id === e.datum.id);

    if (image) {
      dispatch(setDisplayedImage(image));
    }
    else {
      console.warn('Image not found');
    }
  }

  return (
    <Section sx={{ flexShrink: 0, p: 0, ...sx }}>
      <AgCharts options={chartOptions} style={{ height: undefined }}/>
    </Section>
  );
}

export default GraphSection;