import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Patient } from "../../types/patient";
import { CRF_SECTIONS, CrfSection } from "./types";
import Section from "../../components/Section";

const NavButton = ({
  disabled,
  highlight,
  label,
  onClick,
}: {
  disabled?: boolean,
  highlight: boolean,
  label: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
}) => {
  return (
    <Button disabled={disabled} variant='text' onClick={onClick} sx={theme => ({
      mb: 1, ml: '-12px',
      textAlign: 'start', textTransform: 'none',
      color: highlight ? theme.palette.primary.main : theme.palette.text.secondary,
    })}>
      {label}
    </Button>
  );
}

const NavSection = ({
  openSection,
  patient,
  onClickSection,
}: {
  openSection?: string,
  patient?: Patient,
  onClickSection: (id: CrfSection) => void,
}) => {
  return (
    <Section sx={{ alignSelf: 'stretch', overflow: 'hidden', p: 0 }}>
      <Typography variant='h4' sx={{ px: 3, py: 2 }}>Case Report Form</Typography>
      <Divider/>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', px: 3, py: 2 }}>
        {Object.keys(CRF_SECTIONS).map((key) => (
          <NavButton
            key={key}
            disabled={!patient}
            highlight={key === openSection}
            label={CRF_SECTIONS[key]}
            onClick={() => onClickSection(key as CrfSection)}
          />
        ))}
      </Box>
    </Section>
  );
}

export default NavSection;