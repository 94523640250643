import { Patient } from "../../types/patient";
import { dateToString, DUE_DATE_WEEK, isoDateTimeToLuxon, pregnancyWeekToString } from "../../utils/dateTime";

export const getKitIssuedDataProps = (patient?: Patient) => {
  if (!patient?.startTimestamp || !patient?.dueDate) {
    return { primaryValue: undefined };
  }

  const startDate = isoDateTimeToLuxon(patient.startTimestamp);
  const dueDate   = isoDateTimeToLuxon(patient.dueDate);

  const week = Math.max(0, Math.floor(DUE_DATE_WEEK - dueDate.diff(startDate, 'weeks').weeks));

  return {
    primaryValue: dateToString(startDate),
    secondaryValue: pregnancyWeekToString(week),
  }
}

export const getFinishedDataProps = (patient?: Patient) => {
  if (!patient?.completionTimestamp || !patient?.dueDate) {
    return { primaryValue: undefined };
  }

  const finishDate  = isoDateTimeToLuxon(patient.completionTimestamp);
  const dueDate     = isoDateTimeToLuxon(patient.dueDate);

  const week = Math.max(0, Math.floor(DUE_DATE_WEEK - dueDate.diff(finishDate, 'weeks').weeks));

  return {
    primaryValue: dateToString(finishDate),
    secondaryValue: pregnancyWeekToString(week),
  }
}