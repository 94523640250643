import { DateTime } from "luxon"
import { SxProps } from "@mui/material"
import { CrfData, MultiOption } from "../../types/crf"

export enum CrfSection {
  Demographics = 'demo',
  Medical = 'medical',
  ASPRE = 'aspre',
  Endpoints = 'endpoints',
  Maternal = 'maternal',
  Ultrasound = 'ultrasound',
  BirthData = 'birth',
  Neonatal = 'neonatal',
}

export const CRF_SECTIONS: { [key: string]: string } = {
  [CrfSection.Demographics]: 'Demographic Details',
  [CrfSection.Medical]: 'Medical History',
  [CrfSection.ASPRE]: 'ASPRE Screening Data',
  [CrfSection.Endpoints]: 'End-points/PE-related Conditions',
  [CrfSection.Maternal]: 'Adverse Maternal Outcomes',
  [CrfSection.Ultrasound]: 'Last Obstetric Ultrasound Scan',
  [CrfSection.BirthData]: 'Birth Data',
  [CrfSection.Neonatal]: 'Adverse Neonatal Outcomes',
}

export enum CrfDataType {
  Integer,
  Decimal,
  Text,
  Date,
  Boolean,
  Radio,
}

export type CrfInput<T> = {
  label?: string,
  value?: T,
  disabled?: boolean,
  sx?: SxProps,
}

export type CrfInputNumber = CrfInput<string> & {
  type: 'number',
  name: string,
  onChange: ChangeEventHandlerNumber,
  width?: number,
  /** E.g. kg or cm */
  prefix?: string,
  /** E.g. kg or cm */
  suffix?: string,
  minValue?: number,
  maxValue?: number,
  decimal?: boolean,
  format?: RegExp,
}

export type CrfInputText = CrfInput<string> & {
  type: 'text',
  name: string,
  onChange: ChangeEventHandlerText,
  width?: number,
  limit?: number,
}

export type CrfInputDate = CrfInput<DateTime> & {
  type: 'date',
  name: string,
  onChange: ChangeEventHandlerDate,
}

export type CrfInputBoolean = CrfInput<boolean> & {
  type: 'checkbox',
  name: string,
  onChange: ChangeEventHandlerBoolean,
  height?: number,
}

export type CrfInputRadio<T> = CrfInput<T> & {
  type: 'radio',
  name?: string,
  options: MultiOption<T>[],
  row?: boolean,
  onChange: ChangeEventHandlerRadio,
}

export type CrfInputs<T = any> = CrfInputNumber | CrfInputText | CrfInputDate | CrfInputBoolean | CrfInputRadio<T>;

export type ChangeEventHandlerBoolean = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
export type ChangeEventHandlerText = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
export type ChangeEventHandlerNumber = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
export type ChangeEventHandlerDate = (name: string, value: DateTime<boolean> | null) => void;
export type ChangeEventHandlerRadio = (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;

export type CrfSectionProps = {
  crf: CrfData,
  editing: boolean,
  onChangeBoolean: ChangeEventHandlerBoolean,
  onChangeBooleanInverted: ChangeEventHandlerBoolean,
  onChangeText: ChangeEventHandlerText,
  onChangeRadio: ChangeEventHandlerRadio,
  onChangeDate: ChangeEventHandlerDate,
  open: boolean,
  onClick: (id: CrfSection) => void,
}